export const Tool = () => (
	<svg
		fill='#000000'
		version='1.1'
		id='Layer_1'
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512.613 512.613'>
		<g transform='translate(1 1)'>
			<g>
				<g>
					<path
						d='M493.693,408.906c-9.141-9.141-20.701-14.641-32.759-16.529c-34.69-19.979-78.716-58.254-106.334-85.871l-51.05-51.35
				l48.49-48.49c31.573-31.573,61.44-53.76,94.72-70.827c0.14-0.047,0.266-0.117,0.4-0.173c16.321-1.078,32.211-7.766,43.12-19.454
				c14.507-14.507,21.333-34.987,19.627-55.467c0-2.56-1.707-5.12-4.267-6.827s-5.973-1.707-8.533,0l-41.813,24.747
				c-11.093-5.12-17.92-16.213-17.067-29.013l40.96-23.893c2.56-0.853,4.267-4.267,4.267-6.827c0-3.413-1.707-5.973-4.267-7.68
				c-30.72-20.48-71.68-11.947-93.013,17.92c-7.311,10.389-11.314,22.34-12.11,34.444c-17.045,33.16-39.189,63.788-70.664,95.263
				l-47.922,47.922l-45.945-46.215c-31.573-31.573-53.76-61.44-70.827-94.72c-0.213-0.638-0.532-1.224-0.931-1.743
				c-1.471-15.713-8.111-30.339-19.549-41.777c-15.36-15.36-32.427-23.04-51.2-23.04c-2.56,0-5.12,1.707-6.827,4.267
				s-1.707,5.12-0.853,7.68l21.333,46.08v0.853c-5.12,10.24-15.36,17.067-26.453,17.92L22.653,28.32
				c-1.707-1.707-4.267-3.413-6.827-3.413s-5.12,1.707-6.827,4.267c-19.627,29.013-9.387,75.093,22.187,97.28
				c11.141,7.958,23.766,12.202,36.492,12.738c32.674,16.962,62.12,38.941,93.215,70.035l45.094,45.359l-47.654,47.654
				c-17.917,17.917-49.484,40.953-95.554,71.667c-16.058,1.294-31.027,7.981-42.686,19.64
				C5.586,408.053-1.241,428.533,0.466,449.013c0,2.56,1.707,5.12,4.267,6.827s5.973,1.707,8.533,0l41.813-24.747
				c11.093,5.973,17.92,17.067,17.067,29.867l-40.96,23.893c-2.56,0.853-4.267,3.413-4.267,6.827s1.707,5.973,4.267,7.68
				c11.947,7.68,24.747,11.093,37.547,11.093c21.333,0,41.813-10.24,55.467-29.867c8.038-11.423,12.086-24.734,12.258-38.06
				c29.712-45.2,52.138-74.975,69.662-92.5l47.514-47.514l52.326,52.634c27.947,27.947,66.811,71.86,86.582,107.555
				c2.445,11.666,8.36,22.373,16.672,30.685c11.093,11.947,25.6,17.92,41.813,17.92c16.213,0,30.72-5.973,42.667-17.92
				s17.92-27.307,17.92-42.667C511.613,435.36,504.786,420,493.693,408.906z M110.546,471.2c-12.8,17.92-35.84,25.6-56.32,19.627
				l30.72-17.92c2.56-1.707,4.267-4.267,4.267-6.827v-4.267c1.707-19.627-9.387-37.547-26.453-46.08l-4.267-1.707
				c-2.56-1.707-5.12-1.707-7.68,0L18.386,432.8c1.707-10.24,6.827-19.627,14.507-27.307c11.093-10.24,25.6-16.213,40.96-14.507
				c12.96,1.44,24.692,7.754,33.172,17.377c1.248,1.523,2.429,3.122,3.521,4.81c5.12,7.68,8.533,17.067,8.533,27.307
				c0,1.131,0.177,2.09,0.51,2.961C119.331,453.191,116.355,462.901,110.546,471.2z M195.026,339.786
				c-16.18,15.328-36.61,41.707-62.137,79.979c-1.879-5.585-4.493-10.964-7.837-15.979c-8.798-12.569-20.843-21.881-34.757-26.607
				c7.095-4.732,13.784-9.29,20.072-13.678l12.125,12.125c1.707,1.707,3.413,2.56,5.973,2.56s5.12-0.853,5.973-2.56
				c3.413-3.413,3.413-8.533,0-11.947l-10.113-10.113c5.207-3.786,10.094-7.435,14.653-10.947l8.26,8.26
				c1.707,1.707,3.413,2.56,5.973,2.56c1.707,0,4.267-0.853,5.973-2.56c3.413-3.413,3.413-8.533,0-11.947l-6.914-6.914
				c7.38-6.118,13.652-11.77,18.861-16.979l47.787-46.933L242.813,292L195.026,339.786z M400.679,38.56
				c10.24-14.507,25.6-22.187,41.813-22.187c5.12,0,10.24,0.853,13.653,1.707L425.426,36c-2.56,1.707-4.267,4.267-4.267,6.827v4.267
				c-0.853,19.627,9.387,37.547,26.453,46.08l5.12,2.56c2.56,1.707,5.12,1.707,7.68,0l32.427-18.773
				c-1.707,10.24-6.827,19.627-14.507,27.307c-9.531,8.798-21.585,14.431-34.533,14.764c-0.657-0.165-1.364-0.258-2.161-0.258
				c-3.952,0-7.807-0.477-11.523-1.361c-12.356-3.219-23.129-10.938-30.29-21.68C388.733,78.666,388.733,55.626,400.679,38.56z
				 M316.199,169.973c25.6-25.6,46.08-51.2,61.44-78.507c1.707,5.12,4.267,9.387,6.827,13.653
				c4.402,6.602,9.716,12.061,15.582,16.51c5.338,4.217,11.215,7.621,17.518,10.052c-27.235,16.196-51.932,36.643-78.327,63.038
				l-48.027,48.027l-23.823-23.963L316.199,169.973z M17.533,51.36l24.747,38.4c1.707,1.707,4.267,3.413,6.827,3.413
				c17.92,1.707,37.547-10.24,46.08-27.307l2.56-4.267c0.853-2.56,0.853-5.12,0-7.68L81.533,18.933
				c8.533,3.413,17.067,8.533,24.747,16.213c10.24,11.093,16.213,25.6,14.507,40.96c-1.44,12.96-7.754,24.692-17.377,33.172
				c-1.523,1.248-3.121,2.429-4.81,3.521c-1.411,1.098-2.881,2.078-4.402,2.95c-2.699,1.452-5.514,2.652-8.408,3.589
				c-4.553,1.363-9.42,1.994-14.497,1.994c-1.131,0-2.09,0.177-2.961,0.51c-9.75-0.258-19.46-3.234-27.759-9.043
				C20.946,99.146,12.413,72.693,17.533,51.36z M261.864,287.158c-0.425-1.029-1.082-1.935-1.985-2.838l-35.84-35.84
				c-0.824-0.824-1.749-1.445-2.724-1.871l-48.476-48.476c-26.453-26.453-51.2-46.933-78.507-63.147
				c4.267-2.56,9.387-4.267,12.8-6.827c6.602-4.401,12.061-9.716,16.51-15.582c4.218-5.34,7.622-11.219,10.054-17.523
				c17.041,27.237,36.639,51.935,63.036,78.332l145.92,146.773c22.656,22.656,56.862,52.732,88.828,74.824
				c-8.176,2.839-15.823,7.478-22.268,13.923c-7.452,6.92-12.579,15.169-15.383,24.331c-5.87-8.512-12.307-17.184-19.028-25.761
				l10.517-10.517c3.413-3.413,3.413-8.533,0-11.947c-3.413-3.413-8.533-3.413-11.947,0l-9.222,9.222
				c-3.756-4.554-7.549-9.037-11.335-13.412l7.757-7.757c3.413-3.413,3.413-8.533,0-11.947c-3.413-3.413-8.533-3.413-11.947,0
				l-7.025,7.025c-8.023-8.871-15.793-17.042-22.842-24.091L261.864,287.158z M480.893,481.44c-7.68,8.533-18.773,12.8-29.867,12.8
				c-11.093,0-21.333-5.12-29.867-12.8c-6.18-5.562-10.11-12.917-11.814-20.763c0.065-0.726,0.026-1.487-0.132-2.277
				c-1.707-13.653,2.56-27.307,11.947-36.693c9.387-9.387,23.04-13.653,36.693-11.947c0.466,0,0.92-0.024,1.365-0.066
				c8.188,1.59,15.898,5.593,21.675,12.013c8.533,7.68,12.8,18.773,12.8,29.867C493.693,462.666,488.573,472.906,480.893,481.44z'
					/>
					<path
						d='M474.919,443.04h-15.36v-16.213c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533v16.213h-16.213
				c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533h16.213v15.36c0,5.12,3.413,8.533,8.533,8.533
				c5.12,0,8.533-3.413,8.533-8.533v-15.36h15.36c5.12,0,8.533-3.413,8.533-8.533C483.453,446.453,480.039,443.04,474.919,443.04z'
					/>
				</g>
			</g>
		</g>
	</svg>
);
