export const SVGAvatar2 = () => (
	<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 145'>
		<g id='women_3'>
			<rect style={{ fill: '#876450' }} width='145' height='145' />
			<g>
				<path
					style={{ fill: '#10303F' }}
					d='M49.646,74.68c0,0,3.857,14.342,13.682,17.958c15.342,0,17.859,0.322,17.859,0.322
			s11.822-4.246,15.984-20.95c-2.395-25.392-19.945-33.192-19.945-33.192L49.646,74.68z'
				/>
				<g>
					<g>
						<g>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M108.981,115.699c-4.963-9.396-36.482-15.597-36.482-15.597s-31.622,5.701-36.944,15.544
						c-3.67,13.038-6.428,29.198-6.428,29.198L72.499,145l42.712-0.197C115.211,144.803,113.645,127.541,108.981,115.699z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M72.499,100.103c0,0,31.912,5.896,36.875,15.292c4.664,11.842,6.738,29.605,6.738,29.605H72.499
						V100.103z'
							/>
							<rect x='63.813' y='81.001' style={{ fill: '#F1C9A5' }} width='17.373' height='29.077' />
							<rect x='72.499' y='81.001' style={{ fill: '#E4B692' }} width='8.688' height='29.077' />
							<path
								style={{ opacity: 0.1, fill: '#DDAC8C' }}
								d='M63.813,94.475c1.561,4.485,7.867,7.057,12.498,7.057
						c1.676,0,3.307-0.28,4.875-0.795V81.001H63.813V94.475z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M94.837,62.653c0-18.162-10-28.489-22.338-28.489c-12.336,0-22.338,10.327-22.338,28.489
						s10.002,32.886,22.338,32.886C84.837,95.539,94.837,80.815,94.837,62.653z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M92.235,75.247c-4.049-0.416-6.783-4.711-6.098-9.586c0.678-4.878,4.512-8.5,8.564-8.084
						c4.047,0.415,6.15,4.743,5.467,9.622C99.491,72.077,96.284,75.659,92.235,75.247z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M44.407,67.162c-0.684-4.879,2.047-9.17,6.092-9.585c4.053-0.416,7.889,3.206,8.566,8.084
						c0.684,4.875-2.051,9.17-6.1,9.586C48.917,75.659,45.087,72.04,44.407,67.162z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M94.837,62.653c0-18.162-10-28.489-22.338-28.489v61.375
						C84.837,95.539,94.837,80.815,94.837,62.653z'
							/>
						</g>
						<path
							style={{ fill: '#FAE43E' }}
							d='M71.753,38.748c11.531,0,21.109,6.656,23.059,15.418c0.238-1.069,0.369-2.17,0.369-3.294
					c0-10.333-10.488-18.712-23.428-18.712c-12.938,0-23.426,8.379-23.426,18.712c0,1.124,0.131,2.225,0.371,3.294
					C50.646,45.404,60.224,38.748,71.753,38.748z'
						/>
						<path
							style={{ fill: '#1D3D4C' }}
							d='M109.374,115.395c-1.311-2.481-4.506-4.716-8.49-6.665c-6.246,1.154-16.627,1.91-28.385,1.91
					s-22.137-0.756-28.383-1.91c-3.986,1.949-7.18,4.184-8.49,6.665C31.956,128.433,28.888,145,28.888,145h43.611h43.613
					C116.112,145,114.038,127.236,109.374,115.395z'
						/>
					</g>
				</g>
				<path
					style={{ fill: '#10303F' }}
					d='M87.29,43.611c0,0,0.488,9.795-13.381,21.922c3.408-5.597,3.408-9.329,3.408-9.329
			s-2.861,5.977-11.256,11.924c1.004-2.535,3.834-11.719,3.834-11.719s-5.385,8.308-8.121,13.555
			c1.096-4.197,1.732-10.493,6.752-15.303c-4.563,2.535-11.588,10.056-11.314,19.589c-1.643-2.274-1.186-10.231-1.186-10.231
			s-4.105,3.673-2.738,17.839c-1.459-1.748-3.193-16.79-3.193-16.79s-3.07,2.682-2.707,11.193
			c-3.527-6.064-14.645-36.757,21.777-47.808c0,0,20.072-4.49,28.648,13.001c8.578,17.49,0.549,35.157-3.467,41.278
			c1.277-5.597,2.463-10.145,1.734-12.68c-1.369,4.984-5.566,6.034-5.566,6.034s5.109-6.909,4.746-10.758
			c-3.834,5.86-8.394,6.821-8.852,6.646c6.934-8.832,6.934-12.943,6.662-16.091c-3.012,7.259-6.389,8.921-6.389,8.921
			s5.475-10.583,5.475-15.218c-3.74,6.471-4.469,6.734-6.021,8.221C88.874,53.698,88.628,46.645,87.29,43.611z'
				/>
				<polygon
					style={{ fill: '#F06B7E' }}
					points='73.026,122.079 75.108,128.492 81.853,128.492 76.396,132.454 78.479,138.866 
			73.026,134.903 67.571,138.866 69.655,132.454 64.2,128.492 70.942,128.492 		'
				/>
				<polygon
					style={{ fill: '#F06B7E' }}
					points='85.505,127.221 87.019,131.881 91.917,131.881 87.954,134.761 89.468,139.418 
			85.505,136.538 81.542,139.418 83.056,134.761 79.093,131.881 83.991,131.881 		'
				/>
				<polygon
					style={{ fill: '#F06B7E' }}
					points='59.128,127.221 60.64,131.881 65.54,131.881 61.577,134.761 63.091,139.418 
			59.128,136.538 55.163,139.418 56.679,134.761 52.714,131.881 57.612,131.881 		'
				/>
			</g>
		</g>
	</svg>
);
