export const SVGAvatar3 = () => (
	<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 145'>
		<g id='men_3'>
			<rect style={{ fill: '#AD1925' }} width='145' height='145' />
			<g>
				<g>
					<g>
						<g>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M109.375,115.394c-4.965-9.396-36.875-15.292-36.875-15.292s-31.91,5.896-36.875,15.292
						C31.957,128.433,28.889,145,28.889,145H72.5h43.611C116.111,145,114.039,127.236,109.375,115.394z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M72.5,100.102c0,0,31.91,5.896,36.875,15.292c4.664,11.842,6.736,29.606,6.736,29.606H72.5
						V100.102z'
							/>
							<rect x='63.812' y='81' style={{ fill: '#F1C9A5' }} width='17.375' height='29.077' />
							<rect x='72.5' y='81' style={{ fill: '#E4B692' }} width='8.688' height='29.077' />
							<path
								style={{ opacity: 0.1, fill: '#DDAC8C' }}
								d='M63.813,94.474c1.563,4.485,7.869,7.056,12.5,7.056
						c1.676,0,3.305-0.28,4.875-0.794V81H63.813V94.474z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M94.838,62.652c0-18.162-10.002-28.489-22.338-28.489S50.162,44.491,50.162,62.652
						c0,24.429,10.002,32.887,22.338,32.887S94.838,86.063,94.838,62.652z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M91.438,75.246c-4.049-0.424-6.783-4.787-6.098-9.74c0.678-4.957,4.514-8.637,8.564-8.215
						c4.047,0.422,6.777,4.782,6.094,9.739C99.318,71.988,95.486,75.665,91.438,75.246z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M45.16,67.031c-0.684-4.957,2.047-9.317,6.092-9.739c4.053-0.422,7.889,3.259,8.568,8.215
						c0.684,4.953-2.053,9.316-6.1,9.74C49.672,75.665,45.84,71.988,45.16,67.031z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M94.838,62.652c0-18.162-10.002-28.489-22.338-28.489v61.376
						C84.836,95.539,94.838,86.063,94.838,62.652z'
							/>
						</g>
						<path
							style={{ fill: '#303030' }}
							d='M92.594,77.874c-1.201,3.378-3.234,7.44-6.908,7.44c-4.59,0-7.875-3.731-13.141-3.731
					c-0.016,0-0.029,0.002-0.043,0.002c-0.016,0-0.031-0.002-0.047-0.002c-5.266,0-8.547,3.731-13.141,3.731
					c-3.672,0-5.705-4.062-6.906-7.44l-1.039-6.508v7.788c0,0,1.744,9.171,5.094,11.387c2.728,2.424,11.637,6.576,15.992,6.576l0,0
					c0.016,0,0.031-0.002,0.047-0.002c0.014,0,0.029,0.002,0.043,0.002h0.002c4.357,0,13.264-4.152,15.99-6.576
					c3.35-2.216,5.096-11.387,5.096-11.387v-7.788L92.594,77.874z'
						/>
						<path
							style={{ fill: '#FFFFFF' }}
							d='M76.135,87.437c0,0-0.531-2.367-3.635-2.367c-2.836,0-3.635,2.367-3.635,2.367H76.135z'
						/>
					</g>
				</g>
				<path
					style={{ fill: '#303030' }}
					d='M86.063,44.711c0,0-1.861,5.851-15.158,11.701s-19.146,7.712-18.082,13.562
			c-5.714-10.433-3.59-39.224,18.613-39.224C88.854,30.75,88.189,40.988,86.063,44.711z'
				/>
				<path
					style={{ fill: '#303030' }}
					d='M94.713,59.204c0,0-7.721-13.562-13.172-14.226c2.395-7.845,5.6-7.313,5.6-7.313
			S95.791,39.253,94.713,59.204z'
				/>
				<path
					style={{ fill: '#1F3A4D' }}
					d='M109.375,115.394c-2.9-5.487-14.98-9.777-24.535-12.399c0.076-1.333-2.316,6.524-2.316,6.524
			l-9.707,21.768l-9.08-20.298l-3.576-7.994c-9.555,2.622-21.635,6.912-24.535,12.399C31.957,128.433,28.889,145,28.889,145H72.5
			h43.611C116.111,145,114.039,127.236,109.375,115.394z'
				/>
			</g>
		</g>
	</svg>
);
