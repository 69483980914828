export const SVGAvatar6 = () => (
	<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 145'>
		<g id='men_2'>
			<rect style={{ fill: '#D69648' }} width='145' height='145' />
			<g>
				<g>
					<g>
						<path
							style={{ fill: '#F1C9A5' }}
							d='M109.374,115.394c-4.963-9.395-36.874-15.291-36.874-15.291s-31.91,5.896-36.874,15.291
					C31.957,128.432,28.888,145,28.888,145h43.611h43.612C116.112,145,114.04,127.235,109.374,115.394z'
						/>
						<path
							style={{ fill: '#E4B692' }}
							d='M72.499,100.103c0,0,31.911,5.896,36.874,15.291c4.666,11.842,6.738,29.606,6.738,29.606H72.499
					V100.103z'
						/>
						<rect x='63.813' y='81' style={{ fill: '#F1C9A5' }} width='17.374' height='29.077' />
						<rect x='72.499' y='81' style={{ fill: '#E4B692' }} width='8.688' height='29.077' />
						<path
							style={{ opacity: 0.1, fill: '#DDAC8C' }}
							d='M63.813,94.475c1.561,4.485,7.868,7.055,12.499,7.055
					c1.676,0,3.306-0.28,4.875-0.793V81H63.813V94.475z'
						/>
						<path
							style={{ fill: '#F1C9A5' }}
							d='M94.837,62.653c0-18.162-10.001-28.49-22.338-28.49c-12.336,0-22.337,10.328-22.337,28.49
					c0,24.427,10.001,32.885,22.337,32.885C84.837,95.538,94.837,86.063,94.837,62.653z'
						/>
						<path
							style={{ fill: '#E4B692' }}
							d='M91.438,75.247c-4.05-0.424-6.783-4.787-6.098-9.739c0.677-4.957,4.512-8.639,8.563-8.217
					c4.047,0.422,6.776,4.782,6.093,9.739C99.318,71.987,95.487,75.664,91.438,75.247z'
						/>
						<path
							style={{ fill: '#F1C9A5' }}
							d='M45.161,67.03c-0.684-4.957,2.046-9.317,6.091-9.739c4.054-0.422,7.889,3.26,8.568,8.217
					c0.683,4.952-2.053,9.315-6.1,9.739C49.67,75.664,45.84,71.987,45.161,67.03z'
						/>
						<path
							style={{ fill: '#E4B692' }}
							d='M94.837,62.653c0-18.162-10.001-28.49-22.338-28.49v61.375
					C84.837,95.538,94.837,86.063,94.837,62.653z'
						/>
					</g>
				</g>
				<path
					style={{ fill: '#231F20' }}
					d='M109.374,115.394c-2.465-4.667-11.57-8.467-20.116-11.111c-1.94,5.845-8.696,10.163-16.758,10.163
			c-8.06,0-14.817-4.318-16.757-10.163c-8.546,2.644-17.652,6.444-20.117,11.111C31.957,128.432,28.888,145,28.888,145h43.611
			h43.612C116.112,145,114.04,127.235,109.374,115.394z'
				/>
				<path
					style={{ fill: '#FFFFFF' }}
					d='M52.581,105.309c1.46,6.91,9.809,12.212,19.919,12.212s18.459-5.302,19.919-12.212
			c-1.053-0.359-2.111-0.701-3.161-1.026c-1.94,5.845-8.696,10.163-16.758,10.163c-8.06,0-14.817-4.318-16.757-10.163
			C54.693,104.607,53.634,104.949,52.581,105.309z'
				/>
				<g>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M66.513,144.571c-3.413,0-5.289-1.969-5.289-5.411v-1.692c0-2.061,0.615-3.659,2.092-4.491
				c-1.415-0.769-2.092-2.214-2.092-4.337v-0.676c0-3.444,1.876-5.414,5.289-5.414c3.415,0,5.291,1.97,5.291,5.414v0.676
				c0,2.123-0.708,3.568-2.092,4.337c1.477,0.832,2.092,2.431,2.092,4.491v1.692C71.804,142.602,69.928,144.571,66.513,144.571z
				 M64.607,129.285c0,1.723,0.8,2.277,1.906,2.277c1.077,0,1.907-0.555,1.907-2.277v-1.198c0-1.938-0.83-2.461-1.907-2.461
				c-1.075,0-1.906,0.523-1.906,2.461V129.285z M66.513,141.496c1.077,0,1.877-0.522,1.907-2.43v-1.999
				c0-1.692-0.707-2.43-1.907-2.43c-1.199,0-1.906,0.737-1.906,2.43v1.999C64.607,140.974,65.438,141.496,66.513,141.496z'
					/>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M83.775,138.975c0,3.628-1.661,5.597-5.167,5.597c-3.321,0-5.044-1.969-5.044-5.411v-0.555h3.198
				v0.769c0,1.538,0.677,2.122,1.753,2.122c1.2,0,1.876-0.584,1.876-2.645v-3.937c-0.584,1.23-1.63,1.907-3.137,1.907
				c-2.491,0-3.753-1.753-3.753-4.921v-3.938c0-3.444,1.816-5.414,5.137-5.414c3.322,0,5.137,1.97,5.137,5.414V138.975z
				 M80.391,131.624v-3.876c0-1.537-0.676-2.09-1.753-2.09c-1.075,0-1.753,0.553-1.753,2.09v3.876c0,1.538,0.678,2.121,1.753,2.121
				C79.715,133.745,80.391,133.162,80.391,131.624z'
					/>
				</g>
				<path
					style={{ fill: '#231F20' }}
					d='M95.004,57.259c-0.891-14.288-10.622-25.543-22.505-25.543c-11.882,0-21.613,11.255-22.504,25.543
			H95.004z'
				/>
			</g>
		</g>
	</svg>
);
