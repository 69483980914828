export const SVGAvatar4 = () => (
	<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 145'>
		<g id='women_5_1_'>
			<rect style={{ fill: '#DF714E' }} width='145' height='145' />
			<g id='women_5'>
				<path
					style={{ fill: '#412E1F' }}
					d='M58.731,98.338h26.824c0,0,13.773-13.587,13.24-31.219c-0.531-17.63-17.373-28.446-32.442-28.446
			C51.286,38.673,40.116,75.745,58.731,98.338z'
				/>
				<g>
					<g>
						<g>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M109.374,115.395c-4.963-9.396-36.873-15.293-36.873-15.293s-31.912,5.896-36.875,15.293
						C31.958,128.434,28.888,145,28.888,145h43.613h43.611C116.112,145,114.04,127.236,109.374,115.395z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M72.501,100.102c0,0,31.91,5.896,36.873,15.293c4.666,11.842,6.738,29.605,6.738,29.605H72.501
						V100.102z'
							/>
							<rect x='63.813' y='81.001' style={{ fill: '#F1C9A5' }} width='17.375' height='29.077' />
							<rect x='72.501' y='81.001' style={{ fill: '#E4B692' }} width='8.687' height='29.077' />
							<path
								style={{ opacity: 0.1, fill: '#DDAC8C' }}
								d='M63.813,94.474c1.563,4.485,7.869,7.058,12.5,7.058
						c1.676,0,3.305-0.28,4.875-0.796V81.001H63.813V94.474z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M94.837,62.652c0-18.162-10-28.489-22.336-28.489c-12.338,0-22.338,10.327-22.338,28.489
						s10,32.888,22.338,32.888C84.837,95.54,94.837,80.814,94.837,62.652z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M91.438,75.244c-4.049-0.451-6.783-5.088-6.098-10.353c0.678-5.269,4.514-9.179,8.564-8.732
						c4.047,0.448,6.777,5.084,6.094,10.353C99.319,71.781,95.487,75.688,91.438,75.244z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M45.161,66.513c-0.684-5.269,2.047-9.904,6.092-10.353c4.053-0.447,7.889,3.463,8.568,8.732
						c0.684,5.265-2.053,9.901-6.1,10.353C49.673,75.688,45.841,71.781,45.161,66.513z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M94.837,62.652c0-18.162-10-28.489-22.336-28.489V95.54
						C84.837,95.54,94.837,80.814,94.837,62.652z'
							/>
						</g>
						<path
							style={{ fill: '#21503E' }}
							d='M116.112,145c0,0-2.072-17.764-6.738-29.605c-2.721-5.154-13.545-9.251-22.77-11.902
					L72.467,114.59l-14.705-10.914c-9.08,2.655-19.477,6.684-22.137,11.72C31.958,128.434,28.888,145,28.888,145h43.613H116.112z'
						/>
					</g>
				</g>
				<path
					style={{ fill: '#86572B' }}
					d='M63.487,45.618c3.707,6.692,13,10.504,19.705,15.532c11.74,8.8,6.434,28.261,2.363,37.188
			c14.752-8.574,25.568-22.959,20.141-46.271c-5.426-23.312-23.951-33.231-43.113-24.965c-15.77-4.135-31.955,19.813-30.09,41.473
			c1.865,21.656,26.238,29.763,26.238,29.763S44.194,66.074,63.487,45.618z'
				/>
				<path
					style={{ fill: '#FFFFFF' }}
					d='M90.38,104.637c0,0-3.938-1.982-6.863-2c-3.986,2.642-11.049,11.952-11.049,11.952l3.961,4.34
			C76.429,118.929,88.022,105.947,90.38,104.637z'
				/>
				<path
					style={{ fill: '#FFFFFF' }}
					d='M54.556,104.637c0,0,3.938-1.982,6.861-2c3.988,2.642,11.051,11.952,11.051,11.952l-3.961,4.34
			C68.507,118.929,56.915,105.947,54.556,104.637z'
				/>
				<path
					style={{ fill: '#FFFFFF' }}
					d='M72.468,114.589c0,0-6.676,7.264-4.814,12.405c1.861,5.143,0.266,12.057,0.266,12.057l1.977-0.355
			c0,0,1.457-5.872-0.867-12.474C67.94,123.13,70.55,117.023,72.468,114.589z'
				/>
				<path
					style={{ fill: '#FFFFFF' }}
					d='M72.425,114.589c0,0-3.443,10.333,0.332,15.464s4.986,13.388,4.986,13.388l1.727-1.053
			c0,0-0.932-7.047-5.719-13.655C71.509,125.638,71.573,117.946,72.425,114.589z'
				/>
			</g>
		</g>
	</svg>
);
