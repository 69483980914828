export const SVGAvatar1 = () => (
	<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 145'>
		<g id='women_7'>
			<rect style={{ fill: '#9364A8' }} width='145' height='145' />
			<g>
				<path
					style={{ fill: '#471B18' }}
					d='M44.388,101.531H97.63c5.85-1.428,11.301-5.104,11.301-8.182c0.002-6.799-3.691-7.704-1.156-13.565
			c2.533-5.861,2.715-14.082-3.111-20.508c-7.186-7.92-1.621-9.573-7.031-20.523C91.39,26.117,82.3,23.376,73.593,26.141
			c0,0-10.773-2.535-19.961,4.434c-9.188,6.97-4.309,16.888-13.18,24.809c-8.869,7.919-7.604,15.046-2.693,23.759
			C42.507,87.569,31.593,94.262,44.388,101.531z'
				/>
				<g>
					<g>
						<g>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M109.374,115.394c-4.963-9.396-36.875-15.291-36.875-15.291s-31.91,5.896-36.873,15.291
						C31.956,128.433,29.544,145,29.544,145h42.955l42.795-0.094C115.294,144.906,114.038,127.236,109.374,115.394z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M72.499,100.103c0,0,31.912,5.896,36.875,15.291c4.664,11.842,6.42,29.606,6.42,29.606H72.499
						V100.103z'
							/>
							<rect x='63.811' y='81' style={{ fill: '#F1C9A5' }} width='17.375' height='29.077' />
							<rect x='72.499' y='81' style={{ fill: '#E4B692' }} width='8.688' height='29.077' />
							<path
								style={{ opacity: 0.1, fill: '#DDAC8C' }}
								d='M63.812,94.475c1.563,4.485,7.869,7.056,12.5,7.056
						c1.676,0,3.307-0.28,4.875-0.794V81H63.812V94.475z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M94.837,62.653c0-18.162-10-28.489-22.338-28.489c-12.336,0-22.336,10.328-22.336,28.489
						c0,18.162,10,32.886,22.336,32.886C84.837,95.539,94.837,80.815,94.837,62.653z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M91.437,75.245c-4.049-0.451-6.783-5.088-6.098-10.352c0.678-5.269,4.512-9.18,8.564-8.732
						c4.047,0.449,6.775,5.083,6.092,10.353C99.317,71.782,95.485,75.689,91.437,75.245z'
							/>
							<path
								style={{ fill: '#F1C9A5' }}
								d='M45.159,66.513c-0.684-5.269,2.047-9.904,6.092-10.353c4.053-0.448,7.889,3.463,8.568,8.732
						c0.684,5.264-2.053,9.901-6.1,10.352C49.669,75.689,45.839,71.782,45.159,66.513z'
							/>
							<path
								style={{ fill: '#E4B692' }}
								d='M94.837,62.653c0-18.162-10-28.489-22.338-28.489v61.375
						C84.837,95.539,94.837,80.815,94.837,62.653z'
							/>
						</g>
						<path
							style={{ fill: '#471B18' }}
							d='M72.444,43.852c0,0,0.537,8.154,8.742,8.864c8.205,0.709,9.27,6.721,7.674,12.136
					c-1.596,5.415-2.307,8.43,2.127,12.685c4.432,4.254-0.531,20.685-0.531,20.685l16.311-7.762c0,0-3.48-13.193-1.596-20.547
					c1.885-7.353-6.561-12.586-8.688-17.905S83.674,27.5,75.032,28.958c-8.645,1.457-7.774,10.64-7.774,10.64L72.444,43.852z'
						/>
					</g>
				</g>
				<path
					style={{ fill: '#0A7E75' }}
					d='M109.374,115.394c-3.285-6.216-18.359-10.951-28.188-13.41v-4.886H63.812v4.808
			c-9.83,2.459-24.904,7.272-28.186,13.488C31.956,128.433,28.888,145,28.888,145h43.611h43.613
			C116.112,145,114.038,127.236,109.374,115.394z'
				/>
				<path
					style={{ fill: '#471B18' }}
					d='M46.306,101.531h4.588C48.575,93.671,57.2,89.328,58.19,81c1.244-10.484-8.027-18.348,2.658-23.497
			c6.781-3.267,11.596-13.651,11.596-13.651l1.148-14.895l-17.1,3.46l-9.563,20.476c0,0-6.646,18.083-6.381,18.753
			C40.815,72.316,46.306,101.531,46.306,101.531z'
				/>
			</g>
		</g>
	</svg>
);
